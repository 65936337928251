<template>
    <div class="main edit">
        <my-header :title="`添加新品种`"></my-header>
        <div class="editform info_edit">
            <van-form @submit="edit_save" validate-trigger="onSubmit" v-if="conf">
                <van-field v-model="edit_data.title" label="名称" :rules="[{ required: true }]" placeholder="请输入" />
                <van-field v-model="edit_data.cname" label="别名" placeholder="请输入" />
                <van-field v-model="edit_data.price" label="价格" placeholder="￥" />
                <van-field v-model="edit_data.price_unit" label="单位" placeholder="苗" />
                <van-field readonly is-link clickable label="分类" v-model="edit_data.typename" placeholder="选择类别" @click="showPicker = true" />
                <van-popup v-model:show="showPicker" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.types" :default-index="getIndexByVal(conf.types,edit_data.typeid,'id')" :columns-field-names="customFieldName" title="选择类别" show-toolbar @confirm="typeOnConfirm" @cancel="showPicker = false" />
                </van-popup>
                <van-field readonly is-link clickable label="瓣型" v-model="edit_data.petalText" placeholder="请选择" @click="showPetal = true" />
                <van-popup v-model:show="showPetal" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.petals" :default-index="getIndexByVal(conf.petals,edit_data.petal,'id')" :columns-field-names="customFieldName" title="选择瓣型" show-toolbar @confirm="petalOnConfirm" @cancel="showPetal = false" />
                </van-popup>
                <van-field readonly is-link clickable label="唇瓣" v-model="edit_data.tongueText" placeholder="请选择" @click="showTongue = true" />
                <van-popup v-model:show="showTongue" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.tongues" :default-index="getIndexByVal(conf.tongues,edit_data.tongue,'id')" :columns-field-names="customFieldName" title="选择唇瓣" show-toolbar @confirm="tongueOnConfirm" @cancel="showTongue = false" />
                </van-popup>
                <van-field readonly is-link clickable label="捧瓣" v-model="edit_data.sideText" placeholder="请选择" @click="showSide = true" />
                <van-popup v-model:show="showSide" round position="bottom" get-container="#app">
                    <van-picker :columns="conf.sides" :default-index="getIndexByVal(conf.sides,edit_data.side,'id')" :columns-field-names="customFieldName" title="选择捧瓣" show-toolbar @confirm="sideOnConfirm" @cancel="showSide = false" />
                </van-popup>
                <van-field readonly is-link clickable label="特色" v-model="edit_data.typeText" placeholder="请选择" @click="showType = true" />
                <van-popup v-model:show="showType" round position="bottom" get-container="#app">
                    <van-checkbox-group v-model="edit_data.tese" @change="teseOnConfirm">
                        <van-cell-group>
                            <van-cell v-for="(item,index) in conf.teses" clickable :key="item.id" :title="`${item.name}`" @click="toggle(index)">
                                <template #right-icon>
                                    <van-checkbox :name="item.id" ref="checkboxes_type" @click.stop />
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-checkbox-group>
                </van-popup>
                <div class="padding margin-large">
                    <van-button round block type="success" native-type="submit">确认添加</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>
<script>
import { Wiki } from "@/api/wiki.js";
import { Picker, Checkbox, CheckboxGroup } from 'vant';
export default {
    components: {
        [Wiki.name]: Wiki,
        [Picker.name]: Picker,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
    },
    data() {
        return {
            loading: true,
            showPicker: false,
            showPetal: false,
            showTongue: false,
            showSide: false,
            showType: false,
            edit_data: { title: this.$route.params.title, typeid: this.$route.params.typeid },
            customFieldName: { text: 'name', value: 'id' },
            conf:this.$store.get('conf')
        };
    },
    created: function() {
        //this.edit_data.typename = this.getTextById(this.conf.types, this.edit_data.typeid?this.edit_data.typeid:0, 'id');

    },
    computed: function() {

    },
    methods: {
        //选择品种分类
        typeOnConfirm(value) {
            this.showPicker = false;
            this.edit_data.typename = value['name'];
            this.edit_data.typeid = value['id'];
        },
        //选择品种瓣型
        petalOnConfirm(value) {
            this.showPetal = false;
            this.edit_data.petalText = value['name'];
            this.edit_data.petal = value['id'];
        },
        //选择品种唇瓣
        tongueOnConfirm(value) {
            this.showTongue = false;
            this.edit_data.tongueText = value['name'];
            this.edit_data.tongue = value['id'];
        },
        //选择品种捧瓣
        sideOnConfirm(value) {
            this.showSide = false;
            this.edit_data.sideText = value['name'];
            this.edit_data.side = value['id'];
        },
        //选择品种特色
        teseOnConfirm() {
            this.edit_data.typeText = this.getTextByIds(this.conf.teses, this.edit_data.tese, 'id');
        },
        //选择供应商
        supplierOnConfirm(value) {
            this.showSuppliersPicker = false;
            this.price_edit_data.supplier = value['title'];
            this.price_edit_data.sid = value['id'];
        },
        //toggle
        toggle(index) {
            this.$refs.checkboxes_type[index].toggle();
        },
        //基本信息保存
        edit_save() {
            Wiki.addsave({ 'typeid': this.edit_data['typeid'], 'title': this.edit_data['title'], 'cname': this.edit_data['cname'], 'price': this.edit_data['price'], 'price_unit': this.edit_data['price_unit'], 'petal': this.edit_data['petal'], 'tongue': this.edit_data['tongue'], 'side': this.edit_data['side'], 'tese': this.edit_data['tese'], })
                .then(res => {
                    if (res) {
                        this.$toast.success(res.msg);
                        this.$base.goBack(this);
                    }
                })
        },
        //通过ids值获取文字
        getTextByIds(arr, ids, key) {
            if (!Array.isArray(ids)) {
                return '';
            }
            var text_arr = [];
            for (var i = 0; i < arr.length; i++) {
                if (ids.includes(arr[i][key])) {
                    text_arr.push(arr[i].name);
                }
            }
            return text_arr.join(',');
        },
        //通过值获取索引
        getIndexByVal(arr, val, key) {
            var r = 0;
            for (var i = 0; i < arr.length; i++) {
                if (arr[i][key] == val) {
                    r = i;
                    break;
                }
            }
            return r;
        },
        //通过id值获取文字
        getTextById(arr, id, key) {
            if (id < 0 || id >= 99) {
                return '';
            }
            var text = '';
            for (var i = 0; i < arr.length; i++) {
                if (id == arr[i][key]) {
                    text = arr[i].name;
                    break;
                }
            }
            return text;
        },
        //通过分类ID获取分类别名
        getCnameBytypeid(typeid) {
            let types = this.conf.types;
            for (let i in types) {
                if (typeid == types[i]['id']) {
                    return types[i]['name'];
                }
            }
            return false;
        },
    },
    computed: {

        //显示详细信息

    },
    watch: {}
};
</script>
<style lang="less">
</style>